import Header from '../../components/Header'
import HeaderImage from '../../images/massage.jpg'
import Card from '../../UI/Card'
import {plans} from '../../data'
import './plans.css'

const Plans = () => {
  return (
    <>
    <Header title="Programe si servicii" image={HeaderImage}>
    </Header>
    <section className="plans">
      <div className="container plans__container">
        {
          plans.map(({id, name, price, features}) => {
            return <Card key={id} className='plan'>
              <h3>{name}</h3>
              <h1>{`${price}`}</h1>
              <h4>Servicii</h4>
              {
                features.map(({feature, available}, index) => {
                  return <p key={index} className={!available ? 'disabled' : ''}>{feature}</p>
                })
              }
            </Card>
          })
        }
      </div>
    </section>
    </>
  )
}

export default Plans