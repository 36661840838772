import {SiOpenaigym} from 'react-icons/si'



export const links = [
    {
        name: "Acasa",
        path: '/'
    },
    {
        name: "Despre noi",
        path: '/about'
    },
    {
        name: "Galerie",
        path: '/gallery'
    },
    {
        name: "Planuri",
        path: '/plans'
    },
    {
        name: "Specialistii nostri",
        path: '/trainers'
    },
    {
        name: "Contact",
        path: '/contact'
    }
]


export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "KINETOTERAPIE",
        info: "Recuperare medicală, utilizarea mișcării în scop terapeutic",
        description: "Kinetoterapia este o ramură a recuperării medicale care se ocupă cu utilizarea mișcării în scop terapeutic. Prin intermediul exercițiilor, se urmărește reabilitarea și recuperarea funcțională a pacienților care au suferit diverse traume, afecțiuni sau intervenții chirurgicale. Aceste exerciții sunt adaptate în funcție de nevoile și capacitatea de mișcare a fiecărui pacient și au ca obiectiv principal restabilirea mobilității, creșterea rezistenței și forței musculare, corectarea posturii și ameliorarea durerilor. ",
        path: "/programs",
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "FITNESS MEDICAL",
        info: "Îmbunătățirea stării de sănătate și a bunăstării generale",
        description: "Fitness-ul medical este un program de antrenament personalizat, adaptat nevoilor și condițiilor fizice individuale ale unei persoane pentru a îmbunătăți starea de sănătate și bunăstarea generală. Acesta poate include exerciții de rezistență, antrenament cardiovascular, flexibilitate și echilibru, precum și consiliere privind nutriția și stilul de viață sănătos",
        path: "/programs",
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "TAPING-UL MEDICAL",
        info: "Bandajare în scop terapeutic",
        description: "Taping-ul medical este o metodă de bandajare pe piele cu scopul de a susține, proteja sau ameliora anumite probleme ale musculaturii, articulațiilor sau ligamentelor. Acest proces poate ajuta la reducerea durerii, îmbunătățirea funcției și accelerarea procesului de vindecare.",
        path: "/programs",
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "MASAJUL TERAPEUTIC",
        info: "Relaxarea mușchilor tensionați și îmbunătățirea circulației sângelui",
        description: "Masajul terapeutic este o formă de terapie alternativă care implică manipularea țesuturilor moi ale corpului pentru a reduce durerea, a relaxa mușchii tensionați și a îmbunătăți circulația sângelui. Acest tip de masaj poate fi folosit pentru a trata diverse afecțiuni, cum ar fi durerile de spate, migrenele, stresul și alte probleme de sănătate.",
        path: "/programs",
    },
    {
        id: 5,
        icon: <SiOpenaigym/>,
        title: "MASAJUL CU BETE DE BAMBUS",
        info: "Relaxarea mușchilor și a stimularea circulației sângelui.",
        description: "Masajul cu bete de bambus este o tehnică de masaj care folosește bete de bambus de diferite dimensiuni și forme pentru a exercita presiune asupra mușchilor și a țesuturilor moi ale corpului. Aceste bete de bambus sunt rulate, presate sau lovite pe piele pentru a relaxa mușchii și a stimula circulația sângelui. Masajul cu bete de bambus poate fi folosit pentru a calma durerile musculare, pentru a reduce tensiunea și stresul și pentru a îmbunătăți elasticitatea pielii.",
    },
    {
        id: 6,
        icon: <SiOpenaigym/>,
        title: "MASAJUL CU ROCI VULCANICE",
        info: "Relaxarea mușchilor și reducerea stresuli",
        description: "Masajul cu roci vulcanice este o formă de terapie care folosește pietre vulcanice încălzite pentru a relaxa mușchii și a reduce stresul. Pietrele vulcanice sunt adesea folosite pentru că rețin căldura mai mult timp decât alte tipuri de pietre, ceea ce ajută la crearea unei experiențe relaxante și terapeutice. Acestea sunt plasate pe anumite puncte de presiune de-a lungul corpului și folosite pentru a masaja pielea. Acest tip de masaj are beneficii care includ relaxarea mușchilor tensionați, îmbunătățirea circulației sanguine, reducerea stresului și anxietății, stimularea limfatică și revigorarea corpului și minții.",
    }
]








export const values = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Value One",
        desc: "Sănătatea înseamnă  viața, iar viața înseamnă mișcare. Într-un corp sănătos, stă o minte sănătoasă. Ne propunem mai mult decât să recuperăm anumite probleme, ne dorim să inspirăm cât mai multe persoane să își respecte corpul și să își restabilească echilibrul. "
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Value Two",
        desc: "“Timpul este o invenție a mișcării. Cine nu se mișcă, nu vede cum trece timpul.”-Amelie Nothomb"
    },
]









export const faqs = [
    {
        id: 1,
        question: "Când este recomandată kinetoterapia?",
        answer: "În scop terapeutic, când pacientul a suferit o accidentare de natura ortopedică sau a fost supus unei intervenții chirurgicale, în patologiile neurologice (de ex. post-AVC), patologii de natură reumatologica (de ex. artorze), dureri cronice (de ex. durere la nivelul coloanei lombare, dorsale sau cervicale), prezența unei posturi deficitare (de ex. scolioze), etc. În scop profilactic, pentru prevenirea durerilor sau revenirii acestora."
    },
    {
        id: 2,
        question: "Când este recomandat masajul? Ce fel de masaj trebuie sa aleg?",
        answer: "În funcție de problemele cu care va confruntați, veți fi sfătuiti în alegerea masajului. Acesta este recomandat fie in scop terapeutic, daca există dureri musculare, fie in scop de relaxare."
    },
    {
        id: 3,
        question: "Pot face kinetoterapie doar daca am o problema medicală?",
        answer: "Nu. Oricine își dorește să devină mai activ, poate apela la kinetoterapie."
    },
    {
        id: 4,
        question: "Sunt suficiente 10 ședințe?",
        answer: "În funcție de patologie, fiecarui pacient îi este conceput un plan de recuperare personalizat. Pot exista rezultate in 10 ședințe, însă acestea un sunt suficiente. Anumite patologii se recupereaza în luni de muncă (de ex. reconstrucție a ligamentului încrucișat anterior)."
    },
    {
        id: 5,
        question: "Pot sa fac kinetoterapie în sarcină?",
        answer: "Da, atât kinetoterapia cât și masajul sunt recomandate in sarcină, însă numai cu acordul medicului ginecolog."
    }
]








export const testimonials = [
    {
        id: 1,
        name: "Laura Nemeti",
        quote: "Recomand cu încredere! Iulia este o profesionista. a abordat totul cu calm și mereu m-a îndrumat spre o recuperare rapida, ajustând de fiecare data nivelul de complexitate a exercițiilor in funcție de starea mea la momentul respectiv. Recomand sa ii urmați toate sfaturile pentru o recuperare ușoară și rapidă!",
        job: "Student",
        avatar: require("./images/user.jpg")
    },
    {
        id: 2,
        name: "Ana Maria Maftei",
        quote: "Recomand cu tot dragul serviciile Iuliei! Ne-am cunoscut cand eram insarcinata iar spatele meu a fost foarte fericit. In continuare masajul si kinetoterapia ma ajuta sa nu simt prea tare greutatea copilului.",
        job: "Software Egineer",
        avatar: require("./images/user.jpg")
    },
    {
        id: 3,
        name: "Matraszepe Bonbon",
        quote: "Best hands is Brașov. Am inceput ședințele de masaj terapeutic, iar efectele sunt imediate, chiar după primele ședințe am scăpat de dureri de spate. Recomand",
        job: "University Lecturer",
        avatar: require("./images/user.jpg")
    },
    {
        id: 4,
        name: "Lelia Elena Bokos",
        quote: "Recomand cu încredere! Profesionalism si foarte rabdatoare!🤗😊",
        job: "Talking Parrot",
        avatar: require("./images/user.jpg")
    },
]







export const plans = [
    {
        id: 1,
        name: 'KINETOTERAPIE',
        desc: 'This package is perfect for beginners who need constant help',
        price: 'de la 40 RON',
        features: [
            {feature: 'Kinetoterapie individuală adulți - 85RON', available: true},
            {feature: 'Kinetoterapie individuală copii - 95RON', available: true},
            {feature: 'Fitness medical - 85RON', available: true},
            {feature: 'Kinesiotaping - 40RON', available: true}
        ]
    },
    {
        id: 2,
        name: 'MASAJ',
        desc: 'This is the perfect package for beginners who know what their doing',
        price: 'de la 85 RON',
        features: [
            {feature: 'Masaj terapeutic parțial (30 minute) - 85RON', available: true},
            {feature: 'Masaj terapeutic general (50 minute) - 155RON', available: true},
            {feature: 'Masaj de relaxare (1h20 minute) - 180RON', available: true},
            {feature: 'Masaj cu bețe de bambus (60 minute) - 120RON', available: true},
            {feature: 'Masaj cu roci vulcanice (60 minute) - 140RON', available: true}
        ]
    },
    {
        id: 3,
        name: 'ABONAMENTE (10 ședințe)',
        desc: 'This package is perfect for busy people who need home service',
        price: 'de la 745 RON',
        features: [
            {feature: 'Abonament kinetoterapie adulti - 745RON', available: true},
            {feature: 'Abonament kinetoterapie copii - 745RON', available: true},
            {feature: 'Abonament masaj terapeutic parțial (30 minute) - 745RON', available: true},
            {feature: 'Abonament masaj terapeutic general (50 minute) - 1350RON', available: true},
            {feature: 'Abonament masaj de relaxare (1h20 minute) - 1650RON', available: true},
            {feature: 'Abonament masaj cu bețe de bambus (60 minute) - 1050RON', available: true},
            {feature: 'Abonament masaj cu roci vulcanice (60 minute) - 1350RON', available: true},
            {feature: 'Abonament kinetoterapie+masaj parțial - 1400RON', available: true},
        ]
    },
]









const Trainer1 = require('./images/trainer1.jpeg')



export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'Iulia Ciovica',
        job: 'Kinetoterapeut',
        socials: ['https://www.instagram.com/kinetobalance/', 'https://www.facebook.com/profile.php?id=61558479901314']
    },
]