import Header from '../../components/Header'
import HeaderImage from '../../images/stones.jpg'
import Card from '../../UI/Card'
import {programs} from '../../data'
import './programs.css'

const Programs = () => {
  return (
    <>
    <Header title="Programe" image={HeaderImage}>
    </Header>
    <section className="programs">
      <div className="container programs__container">
        {
          programs.map(({id,title,description}) => {
            return <Card key={id} className='plan'>
              <h3>{title}</h3>
              <small>{description}</small>
            </Card>
          })
        }
      </div>
    </section>
    </>
  )
}

export default Programs