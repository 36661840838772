import React from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const location = {
    address: "Strada Buzești 6, Brașov 500449",
    lat: 45.665813,
    lng: 25.585437,
  };
  
  const LocationPin = ({ text }) => (
    <div className="pin">
      <Icon icon={locationIcon} className="pin-icon" />
      <p className="pin-text">{text}</p>
    </div>
  );
  

const Map = () => {
    return (
      <div className="map">
        <h2 className="map-h2">Locatia cabinetului</h2>
        <div className="google-map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: "AIzaSyAtEtjuqDOC1oT0z5IbfGh_lFXLhcp2Wsk" }}
            defaultCenter={location}
            defaultZoom={17}>
            <LocationPin
              lat={location.lat}
              lng={location.lng}
              text={location.address}
            />
          </GoogleMapReact>
        </div>
      </div>
    );
  };

  export default Map;