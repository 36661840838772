import Header from "../../components/Header";
import HeaderImage from "../../images/yoga3.jpg";
import StoryImage from "../../images/strech.png";
import VisionImage from "../../images/man.png";
import MissionImage from "../../images/balance.png";
import "./about.css";

const About = () => {
  return (
    <>
      <Header title="Despre noi" image={HeaderImage}>
      </Header>

      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image ">
            <div className="main__header-circle"></div>
            <div className="main__header-image">
              <img src={StoryImage} alt="Our Story Image" />
            </div>
          </div>
          <div className="about__section-content">
            <h1>Povestea noastra</h1>
            <p>
              Suntem un cabinet mic de recuperare medicală, având misiunea de a
              oferi servicii la cea mai înaltă calitate.
            </p>
          </div>
        </div>
      </section>

      <section className="about__Vision">
        <div className="container about__Vision-container">
          <div className="about__section-content">
            <h1>Viziunea noastra</h1>
            <p>
              Ne propunem să îmbunătățim starea de sănătate a pacienților și să
              le oferim suportul necesar pentru a reveni la o viață activă și
              independentă.{" "}
            </p>
          </div>
          <div className="about__section-image ">
            <div className="main__header-circle"></div>
            <div className="main__header-image">
            <img src={VisionImage} alt="Our Vision Image" />
            </div>
          </div>
        </div>
      </section>

      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image ">
            <div className="main__header-circle"></div>
            <div className="main__header-image">
            <img src={MissionImage} alt="Our Mission Image" />
            </div>
          </div>
          <div className="about__section-content">
            <h1>Misiunea noastra</h1>
            <p>
              Misiunea noastră este să ajutăm pacienții să îsi recapete forța
              și funcționalitatea, recâștigând, astfel, capacitatea de a
              desfășura activități zilnice fără dificultate.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
