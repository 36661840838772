import { Link } from "react-router-dom";
import Logo from "../images/logo.jpg";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <article>
          <Link to="/" className="logo-footer">
            <img src={Logo} alt="Footer Logo" />
          </Link>
        </article>
        <h4>Ne puteti gasi si pe urmatoarele retele de socializare</h4>
        <article>
          
          <div className="footer__socials">
            <a
              href="https://www.facebook.com/profile.php?id=61558479901314"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/kinetobalance/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <AiFillInstagram />
            </a>
          </div>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
