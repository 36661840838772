import Header from "../../components/Header";
import Map from "../../components/Map";
import HeaderImage from "../../images/mats.jpg";
import { MdEmail } from "react-icons/md";
import { BsMessenger } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import "./contact.css";

const Contact = () => {
  return (
    <>
      <Header
        title="Contacteaza-ne pentru o programare!"
        image={HeaderImage}
      ></Header>
      <section className="contact">
        <div className="container contact__container">
          <div className="map-container">
            <Map />

            <div className="contact__wrapper">
              <h2 className="map-h2">
                Pentru mai multe Informatii va rugam sa ne contactati
              </h2>
              <div>Adresa: Strada Buzești 6, Brașov 500449</div>
              <div>Nr telefon: +40 773 973 510</div>
              <div>Program :
                <div>
                  Luni-vineri: 9:00-20:00
                </div>
                <div>
                  Sambata-Duminica: Inchis
                </div>
              </div>
              <a className="mero-link" href="https://mero.ro/p/kineto-balance">Link pentru programari online</a>
              <div>
                <a
                  href="mailto:iulia.ciovica06@gmail.com"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <MdEmail />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=61558479901314"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <BsMessenger />
                </a>
                <a
                  href="https://wa.me/+40773973510"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <IoLogoWhatsapp />
                </a>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>
    </>
  );
};

export default Contact;
